<template>
  <div>
    <b-row
      v-for="(r,i) in apps"
      :key="i"
      class="border mb-1"
      align-v="center"
    >
      <b-col
        cols="12"
        md="1"
      >
        <b-row
          align-h="center"
        >
          <img
            :src="r.photo === '' ? require('@/assets/images/usernoImage.png') : r.photo"
            width="80"
            height="80"
            class="mb-1"
          >
        </b-row>
        <b-row
          align-h="center"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            blocked
            variant="relief-danger"
            @click="onAdayBtnClicked(r)"
          >
            <feather-icon
              icon="UserIcon"
              class="mr-30"
            />
            <span>Özgeçmiş Düzenle</span>
          </b-button>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-row>
          <b-col class="border text-danger">
            Adı Soyadı:
          </b-col>
          <b-col class="border">
            {{ r.applicantName }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="border text-danger">
            Telefon Numarası:
          </b-col>
          <b-col class="border">
            {{ r.applicantPhone }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="border text-danger">
            E-Posta Adresi:
          </b-col>
          <b-col class="border">
            {{ r.applicantEmail }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="border text-danger">
            Başvuru Tarihi:
          </b-col>
          <b-col class="border">
            {{ r.registrationDate }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="border text-danger">
            Güncelleme Tarihi:
          </b-col>
          <b-col class="border">
            {{ r.updatedDate }}
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="5"
      >
        <b-row>
          <b-col class="border text-danger">
            Kişisel Özellik:
          </b-col>
          <b-col class="border">
            <b-row
              v-for="(ey,y) in ceviri(r)"
              :key="y"
              class="pl-1"
            >
              {{ ey }}
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="border text-danger">
            Mesleki Yeterlilik:
          </b-col>
          <b-col class="border">
            <b-row
              v-for="(ex,x) in cevirmeslek(r)"
              :key="x"
              class="pl-1"
            >
              {{ ex }}
            </b-row>

          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="1"
        class="mt-1"
      >
        <b-row
          align-h="center"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            block
            class="mb-1"
            variant="relief-primary"
            @click="onDuzenleBtnClicked(r)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-30"
            />
            <span>Not Yaz</span>
          </b-button>
        </b-row>
        <b-row
          align-h="center"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            block
            class="mb-1"
            variant="relief-warning"
            @click="onDuzenleBtnClicked(r)"
          >
            <feather-icon
              icon="UserXIcon"
              class="mr-30"
            />
            <span>Adayı Pasif Yap</span>
          </b-button>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BButton, VBModal, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import * as apiAday from '@/api/islemler/applicant/applicantInfo'

export default {
  components: {
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      apps: [],
    }
  },
  mounted() {
    this.getApps()
  },
  methods: {
    getApps() {
      apiAday.GetIncompletedApplicants().then(res => {
        this.apps = res.data.list
      })
    },
    onAdayBtnClicked(row) {
      this.$router.push({ name: 'aday-kayit', params: { applicantId: row.applicantId } })
    },
    ceviri(row) {
      let problems = []
      const retvals = []
      let say = ''
      if (row.validationErrors === null) return ''
      problems = row.validationErrors.split(',')
      problems.forEach((value, i) => {
        say = `${i.toString()}`
        switch (value) {
          case 'ApplicantName': retvals.push('Adı Soyadı')
            break
          case 'ApplicantTCKN': retvals.push('T.C. Kimlik No')
            break
          case 'ApplicantEmail': retvals.push('E-Posta Adresi')
            break
          case 'ApplicantPhone': retvals.push('Telefonu')
            break
          case 'City': retvals.push('İli')
            break
          case 'Town': retvals.push('İlçe')
            break
          case 'District': retvals.push('Mahalle')
            break
          case 'Street': retvals.push('Cadde / Sokak')
            break
          case 'DoorNumber': retvals.push('Kapı No')
            break
          case 'AddressDefinition': retvals.push('Açık Adres')
            break
          case 'BirthDate': retvals.push('Doğum Tarihi')
            break
          case 'Gender': retvals.push('Cinsiyet')
            break
          case 'MaritalStatus': retvals.push('Medeni Hali')
            break
          case 'MilitaryStatus': retvals.push('Askerlik Durumu')
            break
          case 'HeightCm': retvals.push('Boyunuz (Cm)')
            break
          case 'WeightKg': retvals.push('Kilonuz (Kg)')
            break
          case 'IsDisabled': retvals.push('Engellilik Durumu')
            break
          case 'IsSmoker': retvals.push('Sigara Kullanımı')
            break
          case 'CanTravel': retvals.push('Seyahat Durumu')
            break
          case 'HasWorkShifts': retvals.push('Vardiyalı Çalışabilme')
            break
          case 'HasReferences': retvals.push('Referans Verebilme')
            break
          case 'HasCriminalRecord': retvals.push('Sabıka Kaydı')
            break
          case 'CanDrive': retvals.push('Aktif Araç Kullanımı')
            break
          case 'DriverLicenseTypeId': retvals.push('Ehliyet Sınıfı')
            break
          default:
            break
        }
      })
      if (retvals.length === 0) {
        say = 'Eksik yoktur'
      } else {
        say = `${retvals.length} adet kayıt eksik`
      }
      retvals.push(say)
      return retvals // .join(', ')
    },
    cevirmeslek(row) {
      let problems = []
      const retvals = []
      let say = ''
      if (row.validationErrors === null) return ''
      problems = row.validationErrors.split(',')
      problems.forEach((value, i) => {
        say = `${i.toString()} adet eksik giriş`
        switch (value) {
          case 'EducationLevelId': retvals.push('Eğitim Seviyesi')
            break
          case 'Position1': retvals.push('İstenilen Pozisyon 1')
            break
          case 'Position1Factor': retvals.push('Faktör 1')
            break
          case 'Position2': retvals.push('İstenilen Pozisyon 2')
            break
          case 'Position2Factor': retvals.push('Faktör 2')
            break
          case 'Position3': retvals.push('İstenilen Pozisyon 3')
            break
          case 'Position3Factor': retvals.push('Faktör 3')
            break
          case 'Position4': retvals.push('İstenilen Pozisyon 4')
            break
          case 'Position4Factor': retvals.push('Faktör 4')
            break
          case 'Position5': retvals.push('İstenilen Pozisyon 5')
            break
          case 'Position5Factor': retvals.push('Faktör 5')
            break
          case 'ExperienceYear': retvals.push('Toplam Tecrübe Yılı')
            break
          case 'ExperienceYearFactorId': retvals.push('Toplam Tecrübe Faktör')
            break
          default:
            break
        }
      })
      if (retvals.length === 0) {
        say = 'Eksik yoktur'
      } else {
        say = `${retvals.length} adet kayıt eksik`
      }
      retvals.push(say)
      return retvals // .join('\n')
    },
  },
}
</script>
